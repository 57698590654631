<template>
  <div class="container-box">
    <div class="left-menu" :class="{'left-menu-show' : show_menu}">
      <h4 class="title">目录</h4>
      <div class="catalogs">
        <div class="li-item" @click="goTo(item.href)" v-for="item in toc" :key="item.id">
          <span :class="{child: item.level == 2}" v-html="item.label" :title="item.label"></span>
        </div>
      </div>
    </div>
    <div class="bot_edit" v-show="!loading">
      <div class="item-edit" @click="show_menu = !show_menu">目录</div>
      <div class="item-edit" @click="prevPage()">上一章</div>
      <div class="item-edit" @click="nextPage()">下一章</div>
      <div class="item-edit" @click="onCtrlSize('-')">字体 -</div>
      <div class="item-edit" @click="onCtrlSize('+')">字体 +</div>
    </div>
    <div class="e-book">
      <!-- 电子书渲染 -->
      <div id="viewer" class="epub-viewer"></div>
      <span class="progress" v-show="!loading">{{progress}}%</span>
    </div>
    <a-spin class="spin" tip="电子书正在加载..." v-show="loading" />
  </div>
</template>

<script>
import ePub from 'epubjs';
export default {
  // 可用组件的哈希表
  components: {},
  // 接收传值
  props: {},
  // 数据对象
  data () {
    return {
      // 电子书加载状态
      loading: true,
      // 电子书实例
      rendition: null,
      // epub电子书
      book: null,
      // 电子书样式
      themes: null,
      // 默认主题设置
      defaultTheme: 0,
      // 字号大小
      defaultFontSize: 14,
      // 主题色
      themeList: [
        {
          name: 'yellow',  // 主题色名称
          itemBg: '#f5e8c6',  // 主题色按钮背景  值应与style下的background相同
          style: { // 主题色样式
            body: {
              'color': '#301d00', 'background': '#f5e8c6'
            }
          }
        }
      ],
      // 目录
      toc: [],
      // 阅读进度
      progress: 0,
      // 目录显隐
      show_menu: false,
    }
  },
  // 事件处理器
  methods: {
    // 修改iframe样式
    loadFrameFn(iframe) {
      const body = iframe.contentWindow.document.head;
      const cssLink = document.createElement("link");
      cssLink.href = "/iframe.css";
      cssLink.rel = "stylesheet";
      cssLink.type = "text/css";
      body.appendChild(cssLink);
    },

    // 生成电子书
    initializeEpub(){
      // 电子书文件地址
      // const bookPath = 'https://cos.hxclass.cn/test/user/homework/20230803/人类群星闪耀时1.epub';
      const bookPath = this.ePubfile

      // 利用epubjs解析文件
      this.book = ePub(bookPath);
      // 挂载渲染电子书
      this.rendition = this.book.renderTo('viewer', {
        flow: "scrolled-doc",
        width: '100%',  // 设置阅读板块宽度
        height: '80vh', // 设置阅读板块高度
      });
      
      // 内容样式
      this.themes = this.rendition.themes;
      this.rendition.display();

      //图书解析完毕回调
      this.book.ready
        .then(() => {
          this.loading = true
          // 生成目录
          this.toc = this.flatTreeAndSetLevel(this.book.navigation.toc);

          // 生成Locations对象
          let generate = this.book.locations.generate();
          return generate;
        })
        .then((result) => {
          this.loading = false
          // 保存locations对象
          this.locations = this.book.locations;

          // 为iframe添加样式
          const iframeWindows = document.getElementsByTagName('iframe');
          if (iframeWindows.length) {
            for(let i = 0;i < iframeWindows.length;i++) {
              this.loadFrameFn(iframeWindows[i])
            }
          }
        });
        
      // 设置默认字体
      this.setFontSize(this.defaultFontSize);
      //初始化主题
      this.registerTheme();
      // 设置默认主题
      this.setTheme(this.defaultTheme);
    },

    // 将目录树形结构扁平化，处理为同级
    flatTreeAndSetLevel(tree, level = 1) {
      const list = []
      tree.forEach(item => {
        const o = JSON.parse(JSON.stringify(item))
        if(o.subitems) delete o.subitems
        o.level = level
        list.push(o)
        if(item.subitems && item.subitems.length) {
          list.push(...this.flatTreeAndSetLevel(item.subitems, level + 1))
        }
      })
      return list
    },

    // 改变字号大小
    onCtrlSize(type){
      if(type == '+'){
        if(this.defaultFontSize == 40) return  // 字号大于40禁用
        this.defaultFontSize ++
      } else if (type == '-'){
        if(this.defaultFontSize == 12) return  // 字号小于12禁用
        this.defaultFontSize --
      }
      this.setFontSize(this.defaultFontSize)
    },

    // 设置字体大小
    setFontSize(fontSize) {
      if (this.themes) {
        this.themes.fontSize(fontSize + 'px')
      }
    },

    //注册背景色（初始化主题）
    registerTheme() {
      this.themeList.forEach((theme) => {
        this.themes.register(theme.name, theme.style);
      });
    },

    //设置背景色
    setTheme(index) {
      this.defaultTheme = index
      this.themes.select(this.themeList[index].name);

      // 同步目录主题色
      this.themeMenu = this.themeList[index].style.body
    },

    // 下一页
    nextPage() {
      this.rendition.next().then(() => {
        this.showProgress() 
      })
    },
    // 上一页
    prevPage() {
      this.rendition.prev().then(() => {
        this.showProgress() 
      })
    },

    // 根据目录跳转指定章节
    goTo(location) {
      this.rendition.display(location);

      // 更新进度
      this.$nextTick(()=>{
        this.showProgress();
      })
    },

    // 进度条跳转更新
    showProgress(){
      var currentLocation = this.rendition.currentLocation();
      var progress = Math.floor(((this.locations.percentageFromCfi(currentLocation.start.cfi)).toFixed(5)) * 10000) / 100;
      this.progress = progress;
      
      // 为iframe添加样式
      const iframeWindows = document.getElementsByTagName('iframe');
      if (iframeWindows.length) {
        for(let i = 0;i < iframeWindows.length;i++) {
          this.loadFrameFn(iframeWindows[i])
        }
      }
    },
  },
  // 生命周期-实例创建完成后调用
  created () { 
    this.ePubfile = this.$AES.decode_data(this.$route.query.url)
  },
  // 生命周期-实例挂载后调用
  mounted () {
    // 加载电子书
    this.$nextTick(()=>{
      this.initializeEpub();
    })
  },
  // 生命周期-实例销毁离开后调用
  destroyed () { },
  // 计算属性监听
  computed: {},
  // 自定义的侦听器
  watch: {}
}
</script>

<style lang="less" scoped>
.child{
  padding-left: 20px;
}
.container-box{
  height: 90vh;
  width: 100%;
  background-color: #ede7da;
  overflow: hidden;
  position: relative;
  .e-book{
    width: 1200px;
    margin: 50px auto;
    position: relative;
    .progress{
      position: absolute;
      right: 0;
      bottom: 0;
      transform: translateX(100%);
    }
  }
  .left-menu{
    width: 250px;
    height: 90vh;
    background: rgb(246, 241, 231);
    position: absolute;
    z-index: 10;
    left: 0;
    top: 0;
    transition: transform 0.5s ease;
    transform: translateX(-100%);
    .title{
      background-color: @theme;
      color: #fff;
      margin: 0;
      line-height: 50px;
      padding-left: 20px;
    }
    .catalogs{
      color: #262626;
      padding: 0 10px;
      height: calc(100% - 50px);
      overflow-y: auto;
      overflow-x: hidden;
      background-color: rgb(246, 241, 231);
      .li-item{
        height: 40px;
        line-height: 40px;
        border-bottom: 1px solid #e1e6f0;
        background-color: rgb(246, 241, 231);
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        cursor: pointer;
        font-weight: 400;
        font-size: 14px;
      }
      .li-item:hover{
        color: @theme;
      }
    }
    /* 设置滚动条的样式 */
    .catalogs::-webkit-scrollbar {
      width:6px;
    }
    /* 滚动槽 */
    .catalogs::-webkit-scrollbar-track {
      -webkit-box-shadow:inset006pxrgba(0,0,0,0.3);
      border-radius:0;
      background:rgba(246, 241, 231, 0.1);
    }
    /* 滚动条滑块 */
    .catalogs::-webkit-scrollbar-thumb {
      border-radius:10px;
      background:rgba(245, 232, 198,.8);
      -webkit-box-shadow:inset006pxrgba(0,0,0,0.2);
    }
  }
  .left-menu-show{
    transform: translateX(0);
  }
  .bot_edit{
    position: fixed;
    right: 0;
    top: 10%;
    background: #fff;
    width: 120px;
    border-radius: 10px  0  0 10px;
    padding: 10px;
    .item-edit{
      font-size: 16px;
      text-align: center;
      padding: 10px 0;
      color: #333333;
      border: 1px solid #cccccc;
      border-radius: 20px;
      margin-bottom: 10px;
      cursor: pointer;
    }
    .item-edit:last-child{
      margin-bottom: 0;
    }
    .item-edit:hover{
      color: @theme;
      border: 1px solid @theme;
    }
  }
  .spin {
    position: absolute;;
    top: 50%;
    left: 50%;
    margin-left: -50px;
    margin-top: -50px;
  }
}
/* 设置滚动条的样式 */
.epub-viewer{
  background:rgba(245, 232, 198,.8);
  /deep/.epub-container:first-child{
    overflow-x: hidden !important;
  }
  ::-webkit-scrollbar {
    width:6px;
  }
  /* 滚动槽 */
  ::-webkit-scrollbar-track {
    -webkit-box-shadow:inset006pxrgba(0,0,0,0.3);
    border-radius:0;
    background:rgb(255, 255, 255);
  }
  /* 滚动条滑块 */
  ::-webkit-scrollbar-thumb {
    border-radius:10px;
    background:rgba(149, 148, 148, 0.8);
    -webkit-box-shadow:inset006pxrgba(0,0,0,0.2);
  }
}
</style>
